import React, { useState, useEffect, useMemo } from "react"
import { Link } from "gatsby"
import {
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Menu,
  MenuItem,
  Typography,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core"
import ReactHtmlParser from "react-html-parser"

// components
import Layout from "../components/layout"
import Banner from "../components/banner"
import Cta from "../components/section-cta"
import { trackDownloadWithPixel } from "./../services/pixels.service"

// json file
import faqs from "../data/faq.json"

// assets
import chevronDown from "../assets/images/faq/chevron-down-gray.svg"
import chevronUpBlack from "../assets/images/faq/chevron-up-black.svg"
import angleUp from "../assets/images/faq/angle-up.svg"

import iconGeneral from "../assets/images/faq/icon-general.svg"
import iconAccount from "../assets/images/faq/icon-account.svg"
import iconSafety from "../assets/images/faq/icon-safety.svg"
import iconSeller from "../assets/images/faq/icon-seller.svg"
import iconBuyer from "../assets/images/faq/icon-buyer.svg"
import iconShipping from "../assets/images/faq/icon-shipping.svg"
import iconPayments from "../assets/images/faq/icon-payments.svg"
import iconSecurity from "../assets/images/faq/icon-security.svg"
import iconBusinessZone from "../assets/images/faq/icon-businesszone.svg"
import iconInHouse from "../assets/images/faq/icon-inhouse.svg"
import iconInApp from "../assets/images/faq/icon-inapp.svg"

const faqList = [...faqs]

const theme = createMuiTheme({
  overrides: {
    MuiMenu: {
      paper: {
        maxHeight: "calc(100% - 290px)",
        width: "100%",
      },
    },
    MuiPopover: {
      paper: {
        boxShadow: "0px 5px 12px 2px rgba(179, 177, 201, 0.15)",
      },
    },
    MuiPaper: {
      root: {
        boxShadow: "none !important",
      },
    },
    MuiTypography: {
      body1: {
        fontFamily: "M PLUS Rounded 1c",
      },
    },
    MuiAccordion: {
      root: {
        backgroundColor: "none",
        $expanded: {
          margin: 1,
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        padding: 0,
      },
    },
    MuiButton: {
      root: {
        width: "100%",
        justifyContent: "space-between",
      },
    },
  },
})

const FAQInner = ({ location }) => {
  const backToTop = () => {
    typeof window !== "undefined" &&
      window.scrollTo({ top: 0, behavior: "smooth" })
  }

  const googlePlayLink =
    "https://play.google.com/store/apps/details?id=com.servbees"
  const appleStoreLink = "https://apps.apple.com/sg/app/servbees/id1530137634"
  const supportEmail = "support@servbees.com"
  const helloEmail = "hello@servbees.com"

  const topicTitle = location.hash.includes("?")
    ? faqList.filter(
        faq =>
          faq.slug === location.hash.substring(0, location.hash.indexOf("?"))
      )[0]?.name
    : faqList.filter(faq => faq.slug === location.hash)[0]?.name

  const [topicSlug, setTopicSlug] = useState(topicTitle)
  const [selectedAccordion, setSelectedAccordion] = useState(topicSlug)
  const [selectedFAQ, setSelectedFAQ] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedTopic, setSelectedTopic] = useState(null)
  const [breadcrumbTopic, setBreadcrumbTopic] = useState(null)
  const [icon, setIcon] = useState(null)

  const handleAccordionChange = panel => (e, newExpanded) => {
    setSelectedAccordion(newExpanded ? panel : null)
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const filteredFaq = useMemo(() => {
    return faqList.find(faq => faq.slug === selectedFAQ)
  }, [selectedFAQ])

  const topics = filteredFaq?.topics.find(faq => faq.slug === location.hash)

  useEffect(() => {
    if (!!location.hash) {
      const slug = location.hash.split("?")?.[0]

      setSelectedAccordion(slug)
      setSelectedFAQ(slug)
      setSelectedTopic(
        `${filteredFaq?.name || ""}: ${
          filteredFaq?.topics.filter(topic => topic.slug === location.hash)[0]
            ?.title || ""
        }`
      )
      setBreadcrumbTopic(filteredFaq?.name || "")
      setIcon(
        filteredFaq?.name.includes("General") ? (
          <img src={iconGeneral} className="faq-inner-icon" alt="icon" />
        ) : filteredFaq?.name.includes("Account") ? (
          <img src={iconAccount} className="faq-inner-icon" alt="icon" />
        ) : filteredFaq?.name.includes("Safety") ? (
          <img src={iconSafety} className="faq-inner-icon" alt="icon" />
        ) : filteredFaq?.name.includes("Seller") ? (
          <img src={iconSeller} className="faq-inner-icon" alt="icon" />
        ) : filteredFaq?.name.includes("Buyer") ? (
          <img src={iconBuyer} className="faq-inner-icon" alt="icon" />
        ) : filteredFaq?.name.includes("Shipping") ? (
          <img src={iconShipping} className="faq-inner-icon" alt="icon" />
        ) : filteredFaq?.name.includes("Payments") ? (
          <img src={iconPayments} className="faq-inner-icon" alt="icon" />
        ) : filteredFaq?.name.includes("In-App") ? (
          <img src={iconInApp} className="faq-inner-icon" alt="icon" />
        ) : filteredFaq?.name.includes("In-House") ? (
          <img src={iconInHouse} className="faq-inner-icon" alt="icon" />
        ) : filteredFaq?.name.includes("Business") ? (
          <img src={iconBusinessZone} className="faq-inner-icon" alt="icon" />
        ) : (
          <img src={iconSecurity} className="faq-inner-icon" alt="icon" />
        )
      )
      backToTop()
    }
  }, [location.hash, filteredFaq])

  return (
    <ThemeProvider theme={theme}>
      <Layout className="faq bg-design vector-dash blue-footer">
        <Banner
          title="Frequently Asked Questions"
          pageTitle={
            typeof window !== "undefined" && window.screen.width > 1199
              ? "Frequently Asked Questions"
              : null
          }
          topic={
            typeof window !== "undefined" && window.screen.width > 1199
              ? breadcrumbTopic
              : null
          }
        />
        <div className="faq-wrapper">
          <div className="faq-inner-grid-desktop">
            <div className="faq-inner-grid left">
              {!!faqList &&
                faqList.map((faq, i) => (
                  <Accordion
                    key={i}
                    square
                    defaultExpanded={location.hash.includes(faq.slug)}
                    expanded={selectedAccordion === faq.slug}
                    onChange={handleAccordionChange(faq.slug)}
                    style={{
                      margin: 0,
                      borderBottom:
                        i !== faqList.length - 1 ? "1px solid #E8E8E8" : "none",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        location.hash.includes(faq.slug) ? (
                          <img
                            src={chevronUpBlack}
                            style={{ transform: "rotate(180deg)" }}
                            alt="icon"
                          />
                        ) : (
                          <img src={chevronDown} alt="icon" />
                        )
                      }
                      aria-controls={`panel${0}-content`}
                      id={`panel${i}-header`}
                    >
                      <h4
                        style={{
                          fontSize: 18,
                          fontWeight: 600,
                          color: location.hash.includes(faq.slug)
                            ? "#1F1A54"
                            : "#91919C",
                        }}
                      >
                        {faq.name}
                      </h4>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div key={faq.id} style={{ display: "block" }}>
                        {faq?.topics.map((sub, j) => {
                          return (
                            <p
                              key={j}
                              className={`faq-topic-menu-text ${
                                location.hash === sub.slug ? "active" : ""
                              }`}
                            >
                              <a
                                href={sub.slug}
                                onClick={() => {
                                  const titleSlug = sub.slug.split("?")?.[0]

                                  setTopicSlug(
                                    faqList
                                      .filter(faq => faq.slug === titleSlug)
                                      .map(topic => topic.name)[0]
                                  )
                                }}
                              >
                                {sub.title}
                              </a>
                            </p>
                          )
                        })}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                ))}
            </div>
            <div className="faq-inner-grid right">
              <div className="faq-topic-title-holder">
                {icon}
                <span className="faq-topic-title">
                  {filteredFaq?.name || ""}
                </span>
              </div>

              <h2 className="faq-topic-text">
                {filteredFaq?.topics.filter(
                  topic => topic.slug === location.hash
                )[0]?.title || ""}
              </h2>
              
              <p>{ReactHtmlParser(topics?.text)}</p>

              {!!filteredFaq &&
                !!topics &&
                !!topics?.subtopics &&
                topics?.subtopics.length > 0 &&
                topics?.subtopics.map(sub => {
                  return (
                    <>
                      {sub.title && sub.title.length > 0 && (
                        <h3 className="faq-subtopic-title">{sub.title}</h3>
                      )}

                      {sub.content.map(item => {
                        return [
                          (
                            <p>{ReactHtmlParser(item.text)}</p>
                          ),
                          item.singleOL && (
                            <ol
                              className="singleOL"
                              start={item.startCount ? item.startCount : 1}
                            >
                              {item.singleOL.map(text => {
                                return (
                                  <li>{ReactHtmlParser(text)}</li>
                                )
                              })}
                            </ol>
                          ),
                          item.doubleOL && (
                            <ol className="doubleOL">
                              {item.doubleOL.map(item => {
                                return [
                                  <li>{ReactHtmlParser(item.text)}</li>,
                                  item.innerOL && item.innerOL.length > 0 && (
                                    <ol
                                      className="innerOL"
                                      style={{ listStyleType: "lower-alpha" }}
                                    >
                                      {item.innerOL.map(text => {
                                        return <li>{ReactHtmlParser(text)}</li>
                                      })}
                                    </ol>
                                  ),
                                  item.innerUL && item.innerUL.length > 0 && (
                                    <ul className="innerUL">
                                      {item.innerUL.map(text => {
                                        return <li>{ReactHtmlParser(text)}</li>
                                      })}
                                    </ul>
                                  ),
                                  item.innerCustomUL &&
                                    item.innerCustomUL.length > 0 && (
                                      <ul
                                        className="innerCustomUL"
                                        style={{ listStyleType: "none" }}
                                      >
                                        {item.innerCustomUL.map(text => {
                                          return (
                                            <li>{ReactHtmlParser(text)}</li>
                                          )
                                        })}
                                      </ul>
                                    ),
                                ]
                              })}
                            </ol>
                          ),
                          item.singleUL && (
                            <ul className="singleUL">
                              {item.singleUL.map(text => {
                                return (
                                  <li>{ReactHtmlParser(text)}</li>
                                )
                              })}
                            </ul>
                          ),
                          item.doubleUL && (
                            <ul className="doubleUL">
                              {item.doubleUL.map(item => {
                                return [
                                  <li>{ReactHtmlParser(item.text)}</li>,
                                  item.innerOL && item.innerOL.length > 0 && (
                                    <ol
                                      className="innerOL"
                                      style={{ listStyleType: "lower-alpha" }}
                                    >
                                      {item.innerOL.map(text => {
                                        return <li>{ReactHtmlParser(text)}</li>
                                      })}
                                    </ol>
                                  ),
                                  item.innerUL && item.innerUL.length > 0 && (
                                    <ul className="innerUL">
                                      {item.innerUL.map(text => {
                                        return <li>{ReactHtmlParser(text)}</li>
                                      })}
                                    </ul>
                                  ),
                                ]
                              })}
                            </ul>
                          ),
                        ]
                      })}
                    </>
                  )
                })}
            </div>
          </div>

          <div className="faq-inner-flex-mobile">
            <div className="faq-inner-topic-selector">
              <p className="topic-selector-label">Select Topic</p>
              <div className="topic-selector-button">
                <Button
                  aria-controls="topic-selector"
                  aria-haspopup="true"
                  onClick={handleClick}
                  endIcon={<img src={chevronDown} alt="icon" />}
                >
                  {selectedTopic ? (
                    <Typography
                      noWrap
                      style={{
                        textTransform: "none",
                        fontWeight: 600,
                        fontFamily: "M PLUS Rounded 1c",
                        paddingBottom: 0,
                      }}
                    >
                      {selectedTopic}
                    </Typography>
                  ) : (
                    "Select A Topic"
                  )}
                </Button>

                {/* <img src={chevronDown} alt='icon' /> */}
              </div>

              <div className="mobile-menu">
                <Menu
                  id="topic-selector"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  elevation={0}
                  style={{
                    boxShadow: "0px 5px 12px 2px #b3b1c9",
                    overflowY: "hidden",
                  }}
                >
                  <Button
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "24px 16px",
                      position: "sticky",
                      top: 0,
                      zIndex: 5,
                      backgroundColor: "#fff",
                    }}
                    onClick={handleClose}
                    endIcon={
                      <img
                        src={chevronDown}
                        style={{ transform: "rotate(180deg)" }}
                        alt="icon"
                      />
                    }
                  >
                    <p
                      style={{
                        fontSize: 12,
                        lineHeight: "18px",
                        marginLeft: 8,
                        color: "#91919c",
                        textTransform: "none",
                      }}
                    >
                      Select Topic
                    </p>
                  </Button>

                  {/* <div style={{overflowY: 'scroll', maxHeight: '100%'}}> */}
                  {!!faqList &&
                    faqList.map((faq, i) => (
                      <MenuItem style={{ padding: "0 24px 1px 24px" }}>
                        <Accordion
                          key={i}
                          square
                          defaultExpanded={location.hash.includes(faq.slug)}
                          expanded={selectedAccordion === faq.slug}
                          onChange={handleAccordionChange(faq.slug)}
                          style={{
                            width: "100%",
                            margin: "0",
                            boxShadow: "none",
                            borderBottom:
                              i !== faqList.length - 1
                                ? "1px solid #e8e8e8"
                                : "none",
                          }}
                        >
                          <AccordionSummary
                            aria-controls={`panel${0}-content`}
                            id={`panel${i}-header`}
                            style={{ padding: 0 }}
                          >
                            <h4
                              style={{
                                fontSize: 18,
                                fontWeight: 600,
                                color: location.hash.includes(faq.slug)
                                  ? "#1F1A54"
                                  : "#91919C",
                              }}
                            >
                              {faq.name}
                            </h4>
                          </AccordionSummary>
                          <AccordionDetails style={{ padding: "0 0 24px 0" }}>
                            <div
                              key={faq.id}
                              style={{ display: "block", maxWidth: "100%" }}
                            >
                              {faq?.topics.map((sub, j) => {
                                return (
                                  <a
                                    href={sub.slug}
                                    onClick={async () => {
                                      const titleSlug = sub.slug.substring(
                                        0,
                                        sub.slug.indexOf("?")
                                      )

                                      await setTopicSlug(
                                        faqList
                                          .filter(faq => faq.slug === titleSlug)
                                          .map(topic => topic.name)[0]
                                      )

                                      handleClose()
                                    }}
                                  >
                                    <p
                                      key={j}
                                      style={{
                                        fontWeight:
                                          location.hash === sub.slug && 700,
                                        color:
                                          location.hash === sub.slug
                                            ? "#1F1A54"
                                            : "#91919C",
                                        marginBottom:
                                          j !== faq?.topics.length - 1 ? 24 : 0,
                                        fontSize: 14,
                                      }}
                                    >
                                      {sub.title}
                                    </p>
                                  </a>
                                )
                              })}
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </MenuItem>
                    ))}
                  {/* </div> */}
                </Menu>
              </div>
            </div>

            <div className="faq-topic-title-holder">
              {icon}
              <h3 className="faq-topic-title">{filteredFaq?.name || ""}</h3>
            </div>
            <h2 className="faq-topic-text">
              {filteredFaq?.topics.filter(
                topic => topic.slug === location.hash
              )[0]?.title || ""}
            </h2>

            <p>{ReactHtmlParser(topics?.text)}</p>

            {!!filteredFaq &&
              !!topics &&
              !!topics?.subtopics &&
              topics?.subtopics.length > 0 &&
              topics?.subtopics.map(sub => {
                return (
                  <>
                    {sub.title && sub.title.length > 0 && (
                      <h3 className="faq-subtopic-title">{sub.title}</h3>
                    )}

                    {sub.content.map(item => {
                      return [
                        (
                          <p>{ReactHtmlParser(item.text)}</p>
                        ),
                        item.singleOL && (
                          <ol className="singleOL">
                            {item.singleOL.map(text => {
                              return (
                                <li>{ReactHtmlParser(text)}</li>
                              )
                            })}
                          </ol>
                        ),
                        item.doubleOL && (
                          <ol className="doubleOL">
                            {item.doubleOL.map(item => {
                              return [
                                <li>{ReactHtmlParser(item.text)}</li>,
                                item.innerOL && item.innerOL.length > 0 && (
                                  <ol
                                    className="innerOL"
                                    style={{ listStyleType: "lower-alpha" }}
                                  >
                                    {item.innerOL.map(text => {
                                      return <li>{ReactHtmlParser(text)}</li>
                                    })}
                                  </ol>
                                ),
                                item.innerUL && item.innerUL.length > 0 && (
                                  <ul className="innerUL">
                                    {item.innerUL.map(text => {
                                      return <li>{ReactHtmlParser(text)}</li>
                                    })}
                                  </ul>
                                ),
                                item.innerCustomUL &&
                                  item.innerCustomUL.length > 0 && (
                                    <ul
                                      className="innerCustomUL"
                                      style={{ listStyleType: "none" }}
                                    >
                                      {item.innerCustomUL.map(text => {
                                        return (
                                          <li>{ReactHtmlParser(text)}</li>
                                        )
                                      })}
                                    </ul>
                                  ),
                              ]
                            })}
                          </ol>
                        ),
                        item.singleUL && (
                          <ul className="singleUL">
                            {item.singleUL.map(text => {
                              return (
                                <li>{ReactHtmlParser(text)}</li>
                              )
                            })}
                          </ul>
                        ),
                        item.doubleUL && (
                          <ul className="doubleUL">
                            {item.doubleUL.map(item => {
                              return [
                                <li>{ReactHtmlParser(item.text)}</li>,
                                item.innerOL && item.innerOL.length > 0 && (
                                  <ol
                                    className="innerOL"
                                    style={{ listStyleType: "lower-alpha" }}
                                  >
                                    {item.innerOL.map(text => {
                                      return <li>{ReactHtmlParser(text)}</li>
                                    })}
                                  </ol>
                                ),
                                item.innerUL && item.innerUL.length > 0 && (
                                  <ul className="innerUL">
                                    {item.innerUL.map(text => {
                                      return <li>{ReactHtmlParser(text)}</li>
                                    })}
                                  </ul>
                                ),
                              ]
                            })}
                          </ul>
                        ),
                      ]
                    })}
                  </>
                )
              })}
            {/* Back to top */}
            <div
              aria-hidden
              className="back-to-top-container"
              onClick={backToTop}
            >
              <div className="back-to-top">
                <img src={angleUp} alt="icon" />
              </div>
            </div>
          </div>
        </div>
        <Cta />
      </Layout>
    </ThemeProvider>
  )
}

export default FAQInner
